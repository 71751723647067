import React from 'react';
import logo_sur from '../../../design/icon/logo_del_sur_w.png';
import './header.css';

function Header() {
  return (
    <div>
    <header className="Header">

        
      <div className="logo">
        <img src={logo_sur} alt="Pronlace Logo" />

      </div>
      


    </header>
    <div className="clear"></div>
    </div>
  );
}

export default Header;

import React from 'react';
import './body.css';
import planta from '../../../design/icon/Planta_Pronalce2.png';
import { NavLink } from "react-router-dom";
function Buttons() {
  return (
    <div className='body'>
      
      <div className="title">
        <h1>Pronalce</h1>
        <p>AVENA, PANCAKES, GRANOLAS</p>
      </div>

            <div className="social">
              {/* Enlaces sociales */}
              <a
                href="https://www.facebook.com/pronalce"
                target="_blank"
                className="icon-facebook"
              >
                <span className="fab fa-facebook" id="logo-facebook"></span>
              </a>

              <a
                href="https://www.instagram.com/pronalce/"
                target="_blank"
                className="icon-instagram"
              >
                <span className="fab fa-instagram" id="logo-instagram"></span>
              </a>

              <a
                href="https://www.linkedin.com/in/pronalce-s-a-43262a59/"
                target="_blank"
                className="icon-linkedin"
              >
                <span
                  className="fa-brands fa-linkedin"
                  id="logo-linkedin"
                ></span>
              </a>
              
            </div>




        <div className="Buttons">
          <a href="https://api.whatsapp.com/send?phone=573216479372&text=Hola%2C%20estoy%20interesado%20en%20comprar%20un%20producto" className="button">Quiero comprar por WhatsApp</a>
          <a href="https://pronalce.myshopify.com/" className="button">Quiero comprar por la página web</a>
          <a href="https://pronalce.com/" className="button">Conoce más sobre nosotros</a>
        </div>

        <div className="copyright">
          <hr />
          <p>
              <img src={planta} alt="" /> Todos los derechos reservados{' '}
              <i className="fa-regular fa-copyright"></i> 2024 <b>PRONALCE S.A</b>
          </p>
        </div>

    </div>



  );
}

export default Buttons;
